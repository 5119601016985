
#root {
 display: flex;	
 height: 100%;
 width: 100%;
}

.App {
	text-align: center;
}

.App-logo {
	max-height: 40vmin;
	pointer-events: none;
}


.App-link {
	color: #3a3939;
	text-decoration: underline;
}

.App-quote {
	font-size: small;
	color: #3a3939;
}

.App-title {
	font-size: large;
}
.App-god {
	font-size: x-large;
	text-transform: uppercase;
}

.App-button {
	width: "150";
	height: "36";
}

.App-answer {
	font-weight: bold;
	color: #595959;
	text-align: center;
	margin: auto;
	width: 50%;
}

.page-container {
	position: relative;
	text-align: center;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.content-wrap {
	flex: 1 0 auto;
}

.footer {
	flex-shrink: 0;
	font-size: small;
	text-align: center;
}

.text-muted {
	opacity: 0.5;
}